<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M5,13 C2.79,13 1,14.79 1,17 C1,19.21 2.79,21 5,21 C7.21,21 9,19.21 9,17 C9,14.79 7.21,13 5,13 M5,19 C3.9,19 3,18.1 3,17 C3,15.9 3.9,15 5,15 C6.1,15 7,15.9 7,17 C7,18.1 6.1,19 5,19 M5,3 C2.79,3 1,4.79 1,7 C1,9.21 2.79,11 5,11 C7.21,11 9,9.21 9,7 C9,4.79 7.21,3 5,3 M11,5 L21,5 L21,7 L11,7 L11,5 M11,19 L11,17 L21,17 L21,19 L11,19 M11,11 L21,11 L21,13 L11,13 L11,11 Z"
    />
  </svg>
</template>
<script>
import { ORDER_BY_FILTER_ICON } from '@/constants'

export default {
  name: ORDER_BY_FILTER_ICON,
}
</script>
